body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #8b8b8b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.mapboxgl-marker {
	z-index: 1;
	text-align: center;
}
.mapboxgl-marker:hover {
	z-index: 1000;
	opacity: 1.0;
}
.markerpin {
	height: 30px;
	width: 30px;
	padding-left: 30px;
	padding: 0px;
	display: inline-block;
	z-index: 3;
	bottom:0px;
	vertical-align: top;
	background-image: url(/static/Marker.svg)
}
.markerbox {
	z-index: 1;
	min-height: 20px;
	/*
	width: 100px;
	overflow: hidden;
	white-space: nowrap;
	*/
	background: white;
	top: 0px;
	margin-bottom:3px;
  	cursor: pointer;
	border: 1px;
	border-style: solid;
	border-color: lightgray;
	color: #000;
	-webkit-box-shadow: 0px 0px 11px -1px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 11px -1px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 11px -1px rgba(0,0,0,0.75);
}
/*
.markerbox:hover{
    overflow: visible;
}*/
.markerbox h3 {
	padding: 5px;
	margin: 0px;
}
.markerbox p {
	padding: 5px;
	margin: 0px;
}
.marker-cluster {
	height: 80px;
	width: 80px;
	background-color: #dc005a;
	border-radius: 50%;
	display: inline-block;
	cursor: pointer;
}
.numberCircle {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    padding: 4px;
    background: #dc005a;
    text-align: center;
    margin: auto auto 8px;
    font: 16px Arial, sans-serif;
}



.shadow {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.redCircle {
	height: 80px;
	width: 80px;
	background-color: #dc005a;
	border-radius: 50%;
	display: inline-block;
	position: relative;
}

.centerCircle {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	color: #ffffff;
	font-size: '18px';
}

.htmlContent {
	font-size: '18px';
}

.htmlContent a {
	color:#dc005a;
	font-size: '18px';
}

.centerVertAndHor {
	position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.pointer {
	cursor: pointer;
}

.MuiGridList-root {
	overflow-y: visible;
}


#menu-appbar .MuiList-root.MuiMenu-list li {
	min-height: 30px;
	padding-top: 1px;
	padding-bottom: 1px;
}
.termini-servizio-text {
	padding: 3px 15px 3px 15px;
	font-size: 14px;
}
.termini-servizio-subtitle {
	font-weight: bold;
	font-size: 16px;
}
.linkgray {
	color: rgb(139, 139, 139);	
}
.MuiButtonBase-root.MuiButton-root.btnProcedi {
	margin-top: 5px;
}
.rowTitlePoint {
	margin-top: 2px,
}
.firstLetterUppercase {
	text-transform: lowercase;
}
.firstLetterUppercase::first-letter {
	text-transform: uppercase;
}
.premi-list-bordered li {
	box-shadow: 0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12);
	width: 47% !important;
	margin: 10px 5px;
}
.premi-list-bordered li .box-list-premio-desc {
	min-height: 75px;
}
.item-block {
	display: block;
}
.bottom-bar-merchant .MuiToolbar-root.MuiToolbar-regular {
	min-height: 35px;
}
/* ratio-4-3 */
.ratio-4-3{
  width:100%;
  position:relative;
  background:url() 50% 50% no-repeat;
  background-size:cover;
  background-clip:content-box;
}
.ratio-4-3:before{
    display:block;
    content:"";
    padding-top:75%;
}

.form-fiera {
	margin-top: 20px;
	padding: 15px;
}
.form-fiera .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
	margin-right: 24px;
}
.admin-toolbar-btn {
	text-align: center;
}
.admin-toolbar-btn button {
	margin: 0 10px 10px 10px;
}

div.MuiPaper-elevation8 {
	box-shadow: none;
	border: 2px solid #F2F2F2;
}

.box-label-icon {
	text-align: center;
}
.label-icon {
	color: rgba(0, 0, 0, 0.54);
	font-size: 14px;
	display: block;
	text-align: center;
}
.info-circle {
	border-radius: 15px;
	width: 13px;
	height: 13px;
	display: inline-block;
}
.info-circle.orange {
	background-color: #e78f37;
}
.info-circle.red {
	background-color: red;
}
.info-circle.green {
	background-color: #6ead46;
}
.legenda-punti {
	font-size: 14px;
	margin-top: 10px;
}
.legenda-punti div {
	display: inline-block;
	padding: 0 12px 0 5px;
}
.legenda-punti span {
	margin-right: 3px;
	margin-bottom: -1px;
}
.col-user {
	width: 100%;
}
.col-user-name {
	display: block;
}
.col-user-img {
	display: block;
}

@media screen and (max-width: 480px) {
  .main-content {
		padding-left: 5px;
		padding-right: 5px;
	}
	
	td.MuiTableCell-sizeSmall,
	th.MuiTableCell-sizeSmall {
    padding: 6px 6px 6px 6px;
	}

	.elencoPremi ul.MuiGridList-root {
		padding-left: 0!important;
		padding-right: 0!important;
	}

	.premi-list-bordered li .box-list-premio-desc {
		min-height: 95px;
	}

	.form-fiera .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
		width: 95%;
	}
}

.smallGrid{ 
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
	grid-gap: 12;
	width: 100%;
 } 


 @media (max-width: 400px){
	.smallGrid{  
		display: grid;
		grid-template-columns: repeat(2, minmax(70px, 1fr));
		grid-gap: 12;
		width: 100%;
	 }
}

.twoElementsGrid{ 
	display: grid;
	grid-template-columns: repeat(2, minmax(70px, 1fr));
	grid-gap: 12;
	width: 100%;
 } 


 @media (max-width: 400px){
	.twoElementsGrid{  
		display: grid;
		grid-template-columns: repeat(2, minmax(70px, 1fr));
		grid-gap: 12;
		width: 100%;
	 }
}
